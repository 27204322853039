import React, {useState} from 'react';

const initialState = {
    nftList: [],
    errorMsg: '',
    defaultAcc: null,
    provider: null,
    signer: null,
    contractNft: null,
    nftAddress: null,
    stakerAddress: null,
    contractStaking: null,
    walletConnected: false, //wallet is connected
    approvalGranted: false, //aprove spending nfts
    setWaiting: false, //loading icon 
};

export const Context = React.createContext();

const Store = ({ children }) => {
    const [state, setState] = useState(initialState);
    return (
        <Context.Provider value={[state, setState]}>{children}</Context.Provider>
    );
}


export default Store;