import {React, useContext, useEffect} from "react";
import './PreWalletEarnContent.css';
import Button from '@mui/material/Button';
import bg from '../img/bg_earn.jpg';
import { Context } from './helper/Store.js';
import {ethers} from 'ethers'
import nftAbi from '../contracts/nft.json'
import stakerAbi from '../contracts/staker.json'


// deployer: 0x1426aefffc9bd9f729943616e5d08043946d4eb8

function PreWalletEarnContent() {

    const nftAddress="0xc9B45dA95b0545f3c675950A7D422b33F9D44A2D";
    const stakerAddress="0x7edb827AbD3DA9DAF0c8437e81d57b9eD6fA03Fc";

    const [state, setState] = useContext(Context);

    const accountChangedHandler = (newAccount) => {
        setState(state => ({...state, defaultAcc: newAccount}));
        updateEthers();
    }

    const connectWalletHandler = async () => {
        if (window.ethereum) {
            window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: "0xA4B1",
                    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                    chainName: "Arbitrum Mainnet",
                    nativeCurrency: {
                        name: "Ether",
                        symbol: "ETH",
                        decimals: 18
                    },
                    blockExplorerUrls: ["https://arbiscan.io/"]
                }] 
            });
            ///testnet
            /*window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: "0x66EEB",
                    rpcUrls: ["https://rinkeby.arbitrum.io/rpc"],
                    chainName: "Arbitrum Testnet",
                    nativeCurrency: {
                        name: "Ether",
                        symbol: "ETH",
                        decimals: 18
                    },
                    blockExplorerUrls: ["https://testnet.arbiscan.io/"]
                }] 
            })*/
            
            window.ethereum.request({method: 'eth_requestAccounts'})
            .then (result => {
            accountChangedHandler(result[0]);
            })
        } 
        else {
            setState(state => ({...state, errorMsg: 'no metamask'}));
        }
    }

    const updateEthers = () => {
        let tempProv = new ethers.providers.Web3Provider(window.ethereum);
        setState(state => ({...state, provider: tempProv}));
    
        let tempSigner = tempProv.getSigner();
        setState(state => ({...state, signer: tempSigner}));
    
        let tempContract = new ethers.Contract(nftAddress, nftAbi.abi, tempSigner);
        setState(state => ({...state, contractNft: tempContract}));
        setState(state => ({...state, nftAddress: nftAddress}));

        tempContract = new ethers.Contract(stakerAddress, stakerAbi.abi, tempSigner);
        setState(state => ({...state, contractStaking: tempContract}));
        setState(state => ({...state, stakerAddress: stakerAddress}));

        setState(state => ({...state, walletConnected: true}));
    }

    

    return(
        <div className="mainContainerPreEarn">
            <img src={bg} className="bgImage"></img>
            <h1 id="boxYourToyHeader" style={{}}>Box Your Toy!</h1>
            <div className="connectWalletButton">
                <Button
                    variant="containded" onClick={connectWalletHandler} sx={{fontFamily: "Masny Font", width: "100px"}}>
                        CONNECT&nbsp;WALLET
                </Button>
            </div>
        </div>
    )
}

export default PreWalletEarnContent;