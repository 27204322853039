import "../../App.css";
import { useContext } from "react";
import PreWalletEarnContent from "../PreWalletEarnContent";
import EarnContent from "../EarnContent";
import { Context } from '../helper/Store.js';

function Earn () {

    const [state, setState] = useContext(Context);

    return (
        <>
            <div>
            {state.walletConnected ? <EarnContent/> : <PreWalletEarnContent/>}
            </div>
        </>        
    )
}

export default Earn;