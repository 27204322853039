import react from 'react';
import React, { useState, useEffect, useContext } from "react";
import './EarnContentMobile.css'
import './EarnContent.css'
import { Context } from './helper/Store.js';

import MobileStakableNft from './MobileStakableNft.js';

import boxAnimated from '../img/box.png';
import bg from '../img/bg_earn.jpg';
import arrowDown from '../img/arrowDown.png'


//images - TEST

function EarnContentMobile() {

    const [state, setState] = useContext(Context);


    window.ethereum.on('accountsChanged', function() {
        setState(state => ({...state, walletConnected: false}));
    })
    
    window.ethereum.on('chainChanged', function() {
        setState(state => ({...state, walletConnected: false}));
    })

    return( 
    <div className="mainContainerEarnMobile" style={{
        margin: "auto auto auto auto",
        justifyContent: "center",
        textAlign: "center"
    }}>
        <img src={bg} className="bgImage"/>
        <img src={boxAnimated} style={{width: "100%", marginTop: "-10%"}}/>
        <div className="mobileStakingContainer">
            <div style={{display: "flex",}}>
                <h1 className="mobileStakingHeader" style={{margin:"auto auto auto auto"}}>Put&nbsp;Your&nbsp;DroEat&nbsp;to&nbsp;Work</h1>
            </div>
            <div className="mobileStakingList" style={{overflowY: "auto", maxHeight: "600px",maxWidth: "550px", marginBottom: "10%", overflowX: "hidden", width:"80%"}}>
                {state.nftList.map((nft)=> {
                    return <MobileStakableNft id={nft.id} img={nft.img} staked={nft.staked} time={nft.time}/>
                })}
            </div>
        </div>
    </div>)
}

export default EarnContentMobile;

//<img src={arrowDown} className="arrowDownStaking" style={{height:"100px", marginLeft:"-17px", marginTop:"3px"}}/>