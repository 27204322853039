import {react, useContext, useState} from 'react';
import { Context } from './helper/Store.js';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

function MobileStakableNft({id, img, staked, time}) {
    
    const [state, setState] = useContext(Context);
    const [stakedTime, setStakedTime] = useState(time);
    const properButtonText = () => 
    {
        if(state.approvalGranted)
        {
            if(staked) return 'Unstake';
            else return 'Stake';
        }
        else return 'Approve';
    }

    const convertStakedTime = () => {
        let day = Math.floor(stakedTime / 86400);
        let hour = Math.floor((stakedTime - day * 86400) / 3600);
        let minute = Math.floor((stakedTime - (hour * 3600 + day * 86400)) / 60);
        let seconds = stakedTime - (hour * 3600 + day * 86400 + minute * 60);
        if(hour<10) hour = "0"+hour;
        if(day<10) day = "0"+day;
        if(minute<10) minute = "0"+minute;
        if(seconds<10) seconds = "0"+seconds;
        let finalTime = day + ":" + hour + ":" + minute + ":" + seconds;
        return finalTime;
    }

    let interval = setInterval(() => {
        let newTime = stakedTime;
        ++newTime;

        setStakedTime(newTime);
        if (interval) clearTimeout(interval);
    }, 1000);

    const properButtonAction = async () => {
        if(!state.approvalGranted)
        {
            setState(state => ({...state, setWaiting: true}));
            let tx = await state.contractNft.setApprovalForAll(state.stakerAddress, true);
            const reciept = await tx.wait()
            if(reciept.status == 1)
            {
                setState(state => ({...state, approvalGranted: true}));
                setState(state => ({...state, setWaiting: false}));
            } 
        }
        else if(state.approvalGranted && staked)
        {
            setState(state => ({...state, setWaiting: true}));
            let tx = await state.contractStaking.unstake(id);
            let reciept = await tx.wait();
            setState(state => ({...state, setWaiting: false}));
        }
        else
        {
            setState(state => ({...state, setWaiting: true}));
            let tx = await state.contractStaking.stake(id);
            let reciept = await tx.wait();
            setState(state => ({...state, setWaiting: false}));
        }
    }

    const properIPFS = () =>
    {
        if(id<10) return "000"+id;
        else if(id<100) return "00"+id;
        else if(id<1000) return "0"+id;
        else return id;
    }

    let ipfs = properIPFS();

    return (
        <Card sx={{display: "flex", width: "90%", maxWidth: "400px",margin: "10% 10% 0 5%", background:"#F2F2F2", borderRadius: "20px"}}>
            <CardMedia sx={{}}>
                <img src={`https://droeats.mypinata.cloud/ipfs/QmYkWUmmxdEWxKfgVrbPvD6fKHQeH4UaSpx2Jr5H1aGqKt/${ipfs}.png`} style={{width: "130%", maxWidth: "200px",height: "90%", borderRadius: "20px", padding: "10px", marginTop: "7%"}}/>
            </CardMedia>
            <Box sx={{display: "flex", flexDirection: "column", padding: "10% 10% 10% 10%",maxWidth: "500px", width: "60%"}}>
            <CardContent sx={{ flex: "auto", fontSize: "100%", fontWeight: "700", color: "#320C66"}}>
                DroEat&nbsp;#{id}
                {staked ? (<div style={{textAlign: "center"}}>{convertStakedTime()}</div>):(<div><br/></div>)}

            </CardContent>
                <Button onClick ={properButtonAction} variant="contained" sx= {{
                    background: 'linear-gradient(87.31deg, #FFC916 2.27%, #FC27DF 54.19%, #00A7FF 100%)',
                    witdh: "60%", marginLeft: "15px", textTransform: "none"
                }}
                >{properButtonText()}</Button>
            </Box>
        </Card>
    )

} 

export default MobileStakableNft

//[process.env.PUBLIC_URL + './DroEatsImages/'+img+'.png']