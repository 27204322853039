import "../../App.css";
import { useContext } from "react";
import { Context } from '../helper/Store.js';
import PlayContent from "../PlayContent";

function Earn () {

    const [state, setState] = useContext(Context);

    return (
        <>
            <PlayContent/>
        </>        
    )
}

export default Earn;