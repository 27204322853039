import React, { useState, useEffect, useContext } from "react";
import { Context } from './helper/Store.js';
import './EarnContent.css';
import EarnContentMobile from './EarnContentMobile.js'

import bg from '../img/bg_earn.jpg';
import box from '../img/empty-box.png';
import boxAnimated from '../img/box.png';
import arrow from '../img/arrow.png';

import DragableNft from './DragableNft.js';
import {useDrop} from 'react-dnd';
import CircularProgress from '@mui/material/CircularProgress';


function EarnContent() {

    const [isDesktop, setDesktop] = useState(window.innerWidth > 1100);
    const [dropBoxEmpty, setDropBoxEmpty] = useState(true);
    const [state, setState] = useContext(Context);
    const [dropBox, setDropBox] = useState([]);


    /*/////////////////////////////////////
    const getDeployer = async() => {
        let depTokens = [];
        let tokenCount = await state.contractNft.balanceOf("0x1426AEFFFc9bD9f729943616e5D08043946D4Eb8");
        for(let i=0;i<tokenCount;i++)
        {
            let tokennn = await state.contractNft.tokenOfOwnerByIndex("0x1426AEFFFc9bD9f729943616e5D08043946D4Eb8",i);
            tokennn = tokennn.toNumber();
            depTokens[i] = tokennn
            console.log(tokennn);
        }
        console.log(depTokens);
    }
    *////////////////////////////////////


    const updateMedia = () => {
        setDesktop(window.innerWidth > 1100);
    };

    const [{isOver}, drop] = useDrop({
        accept: "image",
        drop: (item, monitor) => addImageToDropBox(item.id),
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const isStaked = (id) => {
        for(let i=0;i<state.nftList.length;i++)
        {
            if(state.nftList[i].id == id)
            {
                if(state.nftList[i].staked) return true;
                else return false;
            }
        }
    }

    const addImageToDropBox = async (id) => {

        if(!isStaked(id))
        {

            try{
                setDropBoxEmpty(false);
                let tx = await state.contractStaking.stake(id);
                setState(state => ({...state, setWaiting: true}));
                const reciept = await tx.wait();
                console.log("wchodzi")
                if(reciept.status == 1)
                {
                    console.log("wchodzi 1")
                    for(let i=0;i<state.nftList.length;i++)
                    {
                        if(state.nftList[i].id == id)
                        {
                            console.log("wchodzi 2")
                            const tempList = state.nftList;
                            tempList[i].staked = true;
                            console.log(tempList);
                            setState(state => ({...state, nftList: tempList}));
                            setState(state => ({...state, setWaiting: false}));
                        }
                    }
                    const nftListFiltered = state.nftList.filter((nft) => id === nft.id);
                    setDropBox((dropBox) => [...dropBox, nftListFiltered[0]]);
                } 
            } catch(err) {console.log(err+"dupxo")}
        }
    }

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(() => {
        async function getUserTokens() {
            const address = state.defaultAcc;
            const tokenIds = [];
            const finalTokens = [];
            //not staked
            let tokenCount = await state.contractNft.balanceOf(address);
            for(let i=0;i<tokenCount;i++)
            {
                tokenIds[i] = await state.contractNft.tokenOfOwnerByIndex(address,i);
                finalTokens.push({id: tokenIds[i].toNumber(), img: tokenIds[i].toNumber(), staked:false});
            }
            //staked
            let stakedTokens = await state.contractStaking.getStakedTokens(address);
            for(let j=0;j<stakedTokens.length;j++)
            {
                if(stakedTokens[j] != 9999)
                {
                    let time = await state.contractStaking.stakedTimeInfo(stakedTokens[j]);
                    time = time.toNumber();
                    finalTokens.push({id: stakedTokens[j], img: stakedTokens[j], staked:true, time:time});
                }
            }
            setState(state => ({...state, nftList: finalTokens}));
            if(await state.contractNft.isApprovedForAll(state.defaultAcc, state.stakerAddress))
            {
                setState(state => ({...state, approvalGranted: true}));
            }

        }
        getUserTokens();
    },[state.walletConnected, state.approvalGranted, state.setWaiting]);

    window.ethereum.on('accountsChanged', function() {
        setState(state => ({...state, walletConnected: false}));
    })
    
    window.ethereum.on('chainChanged', function() {
        setState(state => ({...state, walletConnected: false}));
    })

    return (
        <div className="mainCointainerEarn">
            {isDesktop ? (
                <div>
                <img src={bg} className="bgImage"></img>
                <div className="dropContainer" ref={drop}>
                    {dropBoxEmpty ? 
                        <img src={box} className="stakingBox"/> 
                        : 
                        <img src={boxAnimated} className="stakingBox"/>
                    }
                </div>
                <img src={arrow} className="arrowDragAndDrop"/>
                <h1 className="dragAndDropHearder">Drag&nbsp;&&nbsp;Drop</h1>
                <div className= "dragContainer">
                    {state.nftList.map((nft)=> {
                        return <DragableNft id={nft.id} img={nft.img} staked={nft.staked} time={nft.time}/>
                    })}
                </div>
                {state.setWaiting ?
                <div className="MobileStakingHeader" id="pending">
                    Pending...
                    <br></br>
                    <CircularProgress sx={{color: '#FC27DF',margin: '10px'}}/>
                </div> : <></>}
                </div>
            ) 
            :
            (<EarnContentMobile/>)
            }
        </div>
    )
}

export default EarnContent;
