import {React, useContext, useState, useEffect} from "react";
import {useDrag} from 'react-dnd';
import { Context } from './helper/Store.js';
import './EarnContent.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import {ethers} from 'ethers'



function DragableNft({id, img, staked, time}) {
    const [state, setState] = useContext(Context);
    const [stakedTime, setStakedTime] = useState(time);
    const [{isDragging}, drag] = useDrag({
        type: "image",
        item: {
            id: id,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        })
    });


    const convertStakedTime = () => {
        let day = Math.floor(stakedTime / 86400);
        let hour = Math.floor((stakedTime - day * 86400) / 3600);
        let minute = Math.floor((stakedTime - (hour * 3600 + day * 86400)) / 60);
        let seconds = stakedTime - (hour * 3600 + day * 86400 + minute * 60);
        if(hour<10) hour = "0"+hour;
        if(day<10) day = "0"+day;
        if(minute<10) minute = "0"+minute;
        if(seconds<10) seconds = "0"+seconds;
        let finalTime = day + ":" + hour + ":" + minute + ":" + seconds;
        return finalTime;
    }

    let interval = setInterval(() => {
        let newTime = stakedTime;
        ++newTime;

        setStakedTime(newTime);
        if (interval) clearTimeout(interval);
    }, 1000);

    const properButtonAction = async () => {
        if(!state.approvalGranted)
        {
            try{
                let tx = await state.contractNft.setApprovalForAll(state.stakerAddress, true);
                const reciept = await tx.wait()
                setState(state => ({...state, setWaiting: true}));
                if(reciept.status == 1)
                {
                    setState(state => ({...state, approvalGranted: true}));
                    setState(state => ({...state, setWaiting: false}));
                }
            } catch(err) {console.log(err);} 
        }
        else if(state.approvalGranted && staked)
        {
            try{
                setState(state => ({...state, setWaiting: true}));
                let tx = await state.contractStaking.unstake(id);
                let reciept = await tx.wait();
                /*for(let i=0;i<state.nftList.length;i++)
                {
                    if(state.nftList[i].id == id)
                    {
                        state.nftList[i].staked = false;
                    }
                }*/
                let update = state.updateTimer;
                setState(state => ({...state, updateTimer: !update}));
                setState(state => ({...state, setWaiting: false}));
            } catch(err) {console.log(err);}
        }
    }


    const properButtonTheme = (approved, staked) => {
        if((approved && staked) || !approved) return 'linear-gradient(87.31deg, #FFC916 2.27%, #FC27DF 54.19%, #00A7FF 100%)';
        else return 'linear-gradient(87.31deg, rgba(255, 201, 22, 0.3) 2.27%, rgba(252, 39, 223, 0.3) 54.19%, rgba(0, 167, 255, 0.3) 100%)';
    }

    const properButtonText = (approved, staked) => {
        if(approved && !staked) return "";
        else if (!approved) return "Approve";
        else if (staked && approved) return "Unstake";
    }

    const properIPFS = () =>
    {
        if(id<10) return "000"+id;
        else if(id<100) return "00"+id;
        else if(id<1000) return "0"+id;
        else return id;
    }

    let ipfs = properIPFS();
    

    return (
        
        <Card ref={drag} sx={{width: "100%",
                              background:"transparent", 
                              overflow:"initial",
                              textAlign: "center",
                              fontWeight: "700",
                              border: 'transparent',
                              height: '100%',
                              justifySelf: 'center',
                              margin: '0 0 25px 0',
                              maxWidth: '220px',
                              boxShadow: 0}}>
            <CardMedia 
            sx={{width: "100%",
                 height: "100%",
                 maxHeight: "180px",
                 maxWidth: "180px",
                 padding: "10px",
                 background:"#F2F2F2",
                 borderRadius: "10px 10px 0 0",}}>
                     <img src={`https://droeats.mypinata.cloud/ipfs/QmYkWUmmxdEWxKfgVrbPvD6fKHQeH4UaSpx2Jr5H1aGqKt/${ipfs}.png`} style={{width: "100%", height: "100%", borderRadius: "10px"}}/>
                 </CardMedia>
            <CardContent sx={{background:"#F2F2F2", 
                              fontFamily: "Masny Font", 
                              fontStyle: "normal",
                              fontWeight: "600",
                              color: "#320C66",
                              margin: "0 0 0 0",
                              maxHeight: "180px",
                              maxWidth: "180px",}}>
            DroEat #{id}
            <br/>
            {staked ? (<div>{convertStakedTime()}</div>) : (<div><br/></div>)}
            </CardContent>
            <CardActions 
            sx={{justifyContent: "center",
                 background:"#F2F2F2",
                 borderRadius: "0 0 10px 10px",
                 maxWidth: "180px",
                 width: "100%"
                 }}>
                <Button 
                onClick={properButtonAction}
                variant="contained" 
                sx={{background: properButtonTheme(state.approvalGranted,staked)/*'linear-gradient(87.31deg, #FFC916 2.27%, #FC27DF 54.19%, #00A7FF 100%)'*/,
                     width: '13vw',
                     maxWidth: "180px",
                     height: "2.5vw",
                     maxHeight: "40px",
                     fontFamily: "Masny Font",
                     textTransform: "none"
                }}>{properButtonText(state.approvalGranted,staked)}</Button>
            </CardActions>
        </Card>);
}

export default DragableNft;
