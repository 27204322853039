import {React, useContext, useEffect} from "react";
import { Context } from './helper/Store.js';
import './PlayContent.css';

import bg from '../img/bg_earn.jpg';
import voxel from '../img/voxel.png';
import './template.css'
import pdf from '../whitepaper.pdf';

function PlayContent() {
    const [state,setState] = useContext(Context);
    const DOC_URL = '/whitepaper.pdf';
    return(
        <div className="MainPlayContainer" style={{textAlign: 'center', alignContent: 'center'}}>
            <img src={bg} className="bgImage"></img>
            <div className="voxelGraphics">
                <img src={voxel} className="voxel" style={{width: '100%', maxWidth: '1400px'}}></img>
            </div>
            <a href={pdf} target="_blank" rel='noreferrer'>
                <div className="playButton" style={{width: '40%', maxWidth:'700px', margin: '-20px auto 50px auto'}}>
                    Whitepaper
                </div>
            </a>
        </div>
    );
}

export default PlayContent;