// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./components/Aeonik-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: Masny Font;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n}\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: \"Masny Font\";\n}\n\nhtml {\n}\n\nbody {\n  overflow-y:auto;\n  overflow-x:hidden;\n  height: 100vh;\n  width: 100vw;\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: auto;\n  margin-top: auto;\n  background-color: rgb(255, 255, 255);\n}\n\n.home {\n  display: flex;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  font-size: 3rem;\n}\n\n@media only screen and (max-width:1100px){\n  .body{\n    overflow: visible;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,+DAA8D;AAChE;;AAEA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,yBAAyB;AAC3B;;AAEA;AACA;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@font-face {\n  font-family: Masny Font;\n  src: url(\"./components/Aeonik-Regular.otf\") format(\"opentype\");\n}\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: \"Masny Font\";\n}\n\nhtml {\n}\n\nbody {\n  overflow-y:auto;\n  overflow-x:hidden;\n  height: 100vh;\n  width: 100vw;\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: auto;\n  margin-top: auto;\n  background-color: rgb(255, 255, 255);\n}\n\n.home {\n  display: flex;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  font-size: 3rem;\n}\n\n@media only screen and (max-width:1100px){\n  .body{\n    overflow: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
