import './Navbar.css';

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { Context } from './helper/Store.js';

import logo from '../img/logo.png';
import { FaTimes } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa';
import { FaDiscord } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

function Navbar() {

  const [state, setState] = useContext(Context);
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    }
    else {
      setButton(true);
    }
  }

  const properIcon = (click) => {
    if (click) {
      return (<FaTimes className='fa-times' />);
    }
    else {
      return (<FaBars className='fa-bars' />);
    }
  }

  useEffect(() => { showButton(); }, []);
  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className={click ? 'navbar-clicked' : 'navbar'}>
        <div className="navbarContainer">
          <Link onClick={closeMobileMenu} to="/" className="navbarLogo">
            <img className="logo" src={logo}></img>
          </Link>
          <div className="menuIcon" onClick={handleClick}>
            {properIcon(click)}
          </div>
          <ul className={click ? 'navMenu active' : 'navMenu'}>
            <li className="navItem">
              <a href='https://trove.treasure.lol/collection/droeats' target='_blank'>Buy</a>
            </li>
            <li className="navItem">
              Team
            </li>
            <li className="navItem">
              Toys&nbsp;(soon)
            </li>
            <li className='navItem' id='navbarBlankSpace'>
            </li>
            <li className="navItem" id='navbarDiscordButton'>
              <a href='https://discord.gg/mintersworld' target='_blank'><FaDiscord size="40px" color='white'/></a>
            </li>
            <li className="navItem" id='navbarTwitterButton'>
              <a href='https://twitter.com/droeats' target='_blank'><FaTwitter  size="40px" color='white'/></a>
            </li>
            <a onClick={closeMobileMenu} href='https://trove.treasure.lol/collection/droeats' target='_blank' style={{color: 'white'}}>
              <li className="navItem" id='navbarPlayButton'>
                Buy
              </li>
            </a>
            <Link onClick={closeMobileMenu} to='/earn' style={{color: 'white'}}>
              <li className="navItem" id='navbarPlayButton'>
              Earn
              </li>
            </Link>
            <Link onClick={closeMobileMenu} to='/play' style={{color: 'white'}}>
              <li className="navItem" id='navbarPlayButton'>
              Play
              </li>
            </Link>
            
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar

