import "../../App.css";
import Template from "../template.js";

function Home () {
    return (
        <>
            <Template/>
        </>
        
    )
}

export default Home;