import React, { useState, useEffect, useContext } from "react";
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import bg from '../img/bg_earn.jpg';
import "./template.css";
import { Link } from "react-router-dom";

import image from "../img/droeats.png";


function Template() {

    return (

        <div class="mainContainer">
            <img src={bg} className="bgImage"></img>
            <div class="information">

                <div class="informationText">

                    <textHeader>DroEats&nbsp;on&nbsp;Arbitrum</textHeader> <br></br><br></br>
                    <div>
                        <textContent>
                            DroEats love cooking and eating! That’s why they’ve made their own restaurant chains, and now compete with each other. <br></br><br></br>

                            Play & Build Your Restaurant on Arbitrum<br></br>
                        </textContent>
                    </div>
                </div>

                <Link to='/earn'>    
                    <div className="earnButton">
                        Earn
                    </div>
                </Link>
            </div>

            <div class="droeatsContainer">
                <img src={image} className="droeats" />
            </div>

        </div>
    )
}

export default Template;
