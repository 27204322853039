import './App.css';
import Home from './components/pages/Home.js';
import Store from './components/helper/Store.js';
import Navbar from './components/Navbar.js';
import Earn from './components/pages/Earn.js';
import Play from './components/pages/Play.js';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <DndProvider backend = {HTML5Backend}>
      <Router>
        <Store>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/earn' element={<Earn/>}/>
          <Route path='/play' element={<Play/>}/>
        </Routes>
        </Store>
      </Router>
      </DndProvider>
    </>
  );
}

export default App;
